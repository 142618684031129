
import PDEIconButton from '@/components/pde/PDEIconButton.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'

export default {
  name: 'HelpGenericSection',
  components: {
    PDEIconButton,
    PDEHeading,
  },
  props: {
    sectionTitle: {
      type: String,
      required: true,
    },
  },
}
